import React from "react";
import Slider from "react-slick";


export const Tarifs = (props) => {
  var sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  return (
    <div id="tarifs">
      <div className="container">
        <div className="section-title text-center">
          <h2>Tarifs</h2>
        </div>
        <Slider {...sliderSettings}>
          <img src="img/tarifs/Tarifs_1.png" alt="tarif1" className="tarifs_img" />
          <img src="img/tarifs/Tarifs_2.png" alt="tarif2" className="tarifs_img" />
          <img src="img/tarifs/Tarifs_3.png" alt="tarif3" className="tarifs_img" />
        </Slider>
      </div>
    </div>
  );
};
